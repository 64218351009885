<script lang="ts" setup>
const trustFactors = [
  {
    title_en: 'Trusted in<br>Indonesia',
    title_id: 'Terpercaya di<br>Indonesia',
    body_en: 'Supporting local businesses<br>Jago Premium Quality',
    body_id: 'Mendukung usaha lokal.<br>Kualitas Premium Jago',
    button: {
      text_en: 'Buy Now',
      text_id: 'Beli Sekarang',
      url: { name: 'brand-brand', params: { brand: 'apple' } },
    },
    background: 'https://cdn.jagofon.com/mockdata/trust-factors/indonesia.png',
  },
  {
    title_en: 'IMEI Tested &<br>Functional',
    title_id: 'IMEI Diuji &<br>Berfungsi',
    body_en: '<br><br>',
    body_id: '<br><br>',
    button: {
      text_en: 'Find out why',
      text_id: 'Cari tahu alasannya',
      url: 'about-us',
    },
    background: 'https://cdn.jagofon.com/mockdata/trust-factors/imei.png',
  },
]

const { locale } = useI18n()
const localePath = useLocalePath()
const localizedTrustFactors = trustFactors.map((trustFactor) => ({
  title: locale.value === 'en' ? trustFactor.title_en : trustFactor.title_id,
  body: locale.value === 'en' ? trustFactor.body_en : trustFactor.body_id,
  button: {
    url: localePath(trustFactor.button.url),
    text:
      locale.value === 'en'
        ? trustFactor.button.text_en
        : trustFactor.button.text_id,
  },
  background: trustFactor.background,
}))
</script>

<template>
  <div class="relative">
    <NuxtPicture
      v-for="(factor, index) in localizedTrustFactors"
      :key="factor.title"
      :src="factor.background"
      :alt="factor.title"
      :index="index"
      :img-attrs="{
        class:
          'absolute h-1/2 w-full object-cover md:h-full md:w-1/2 ' +
          (index === 0 ? 'left-0 top-0' : 'bottom-0 right-0'),
      }"
      sizes="400px md:1000px"
      densities="1x"
    />
    <div class="container relative flex flex-wrap">
      <div
        v-for="factor in localizedTrustFactors"
        :key="factor.title"
        class="shrink-0 basis-full p-4 py-8 text-white md:basis-1/2 md:p-12"
      >
        <h3 class="mb-4 font-serif text-4xl" v-html="factor.title" />
        <p class="mb-6" v-html="factor.body" />
        <UButton
          variant="link"
          class="underline"
          color="white"
          :to="factor.button.url"
          :padded="false"
        >
          {{ factor.button.text }}
        </UButton>
      </div>
    </div>
  </div>
</template>
