<script lang="ts" setup>
import type { AccordionItem } from '#ui/types'
import type { Faq } from '~/types/api'

interface Props {
  faqs?: Faq[]
}

const props = defineProps<Props>()
const faqs = props.faqs
  ? props.faqs
  : (await useNuxtApp().$api.content.getFaqs()).data.value

const shown = ref(3)
const items: ComputedRef<AccordionItem[]> = computed(() =>
  faqs
    .map<AccordionItem>((faq) => ({
      label: faq.question,
      content: faq.answer, // TODO: Sanitize HTML
    }))
    .slice(0, shown.value),
)

useSchemaOrg([
  defineWebPage({
    '@type': 'FAQPage',
  }),
  ...faqs.map(({ question, answer }) =>
    defineQuestion({
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer,
      },
    }),
  ),
])
</script>

<template>
  <div>
    <UAccordion
      color="black"
      :items="items"
      :ui="{
        item: {
          padding: 'p-6 pt-0',
        },
      }"
    >
      <template #default="{ item, open }">
        <UButton
          color="black"
          variant="ghost"
          size="lg"
          :ui="{
            rounded: 'rounded-none',
            font: 'font-medium',
            padding: { lg: 'p-6' },
          }"
        >
          <span class="text-left">{{ item.label }}</span>

          <template #trailing>
            <UIcon
              name="i-far-chevron-down"
              class="ms-auto size-4 shrink-0 transition-transform duration-200"
              :class="[open && '-rotate-180']"
            />
          </template>
        </UButton>
      </template>

      <template #item="{ item }">
        <div class="prose max-w-none" v-html="item.content" />
      </template>
    </UAccordion>

    <div v-if="faqs.length > shown" class="text-center">
      <UButton
        :label="$t('global.show-more')"
        color="indigo"
        variant="link"
        size="lg"
        :ui="{
          font: 'font-medium',
        }"
        @click="shown += 5"
      />
    </div>
  </div>
</template>
