<script lang="ts" setup>
const { locale } = useI18n()
const localePath = useLocalePath()

const testsEn = [
  'Buttons',
  'Proximity sensor',
  'Battery (Minimum 80%)',
  'Oxidation',
  'Wifi - Bluetooth - 3G/4G',
  'Headphone jack',
  'Speakers and Microphone',
  'Charging port',
  'SIM card reader',
  'Touch/Face ID',
  'Cameras',
  'Flash',
  'IMEI code',
  'Cellular network',
]

const testsId = [
  'Tombol',
  'Sensor Proximity',
  'Baterai (Minimal 80%)',
  'Oksidasi',
  'Wifi - Bluetooth - 3G/4G',
  'Jack Headphone',
  'Speaker dan Mikrofon',
  'Port Charger',
  'Pembaca Kartu SIM',
  'Touch/Face ID',
  'Kamera',
  'Flash',
  'Kode IMEI',
  'Jaringan',
]

const tests = locale.value === 'en' ? testsEn : testsId
</script>

<template>
  <div>
    <div class="mb-3 flex items-center justify-between">
      <h2 class="font-serif text-4xl capitalize">
        {{ $t('pages.home.quality-assurance') }}
      </h2>
      <UButton
        :to="localePath('/about-us')"
        :label="$t('global.learn-more')"
        variant="link"
        color="indigo"
        size="lg"
        :padded="false"
        class="hidden underline md:block"
      />
    </div>
    <div class="flex gap-6">
      <div class="hidden basis-1/2 overflow-hidden rounded-lg md:block">
        <ScriptYouTubePlayer
          video-id="p9G_TVLoEYM"
          :root-attrs="{
            style: { aspectRatio: 'unset', width: '100%', height: '100%' },
          }"
        >
          <template #awaitingLoad>
            <div
              class="absolute left-1/2 top-1/2 h-[48px] w-[68px] -translate-x-1/2 -translate-y-1/2"
            >
              <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
                <path
                  d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                  fill="#f00"
                />
                <path d="M 45,24 27,14 27,34" fill="#fff" />
              </svg>
            </div>
          </template>
          <template #loading />
          <template #placeholder>
            <NuxtImg
              src="https://i.ytimg.com/vi/p9G_TVLoEYM/hqdefault.jpg"
              alt="Video Placeholder"
              class="absolute inset-0 size-full object-cover object-center"
              loading="lazy"
            />
          </template>
        </ScriptYouTubePlayer>
      </div>
      <UCard class="basis-full md:basis-1/2">
        <h3 class="mb-4">
          {{ $t('pages.home.quality-assurance-content') }}
        </h3>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div
            v-for="(test, i) in tests"
            :key="i"
            class="flex items-center gap-4"
          >
            <div
              class="flex rounded-full border border-primary-500 bg-primary-500 p-1"
            >
              <UIcon name="i-far-check" class="size-4 text-white" />
            </div>
            <span class="text-sm capitalize">{{ test }}</span>
          </div>
        </div>
      </UCard>
    </div>
  </div>
</template>
