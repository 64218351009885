<script lang="ts" setup>
const { data: reviews } = await useReview()

const rating = computed(() => {
  return (
    reviews.value.reduce((acc, review) => acc + review.rating, 0) /
    reviews.value.length
  )
})

const values = useValueProposition().slice(0, 4)
</script>

<template>
  <UCard :ui="{ body: { padding: 'md:p-12' } }">
    <div class="flex gap-6">
      <div class="hidden basis-full md:block md:basis-1/2">
        <h2 class="mb-3 font-serif text-4xl capitalize">
          {{ $t('pages.home.about-us') }}
        </h2>
        <p
          v-for="(item, index) in $tm('pages.home.about-us-content')"
          :key="index"
          class="mb-4 leading-loose text-gray-900"
        >
          {{ $rt(item) }}
        </p>
        <UIRating :rating="rating" with-text />
      </div>
      <div class="basis-full md:basis-1/2">
        <UIValues
          :values="values"
          :ui="{
            container: 'md:grid-cols-2 gap-6',
          }"
        />
      </div>
    </div>
  </UCard>
</template>
